import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "estilos",
      "style": {
        "position": "relative"
      }
    }}>{`Estilos`}</h1>
    <p>{`O Bold usa `}<em parentName="p">{`CSS-in-JS`}</em>{` para aplicar estilo aos seus componentes, de forma que todo CSS é incluído junto ao `}<em parentName="p">{`bundle`}</em>{` JS e inserido no DOM em tempo de execução. Nós usamos o `}<a parentName="p" {...{
        "href": "https://emotion.sh"
      }}>{`emotion`}</a>{`, uma biblioteca de CSS-in-JS para isso.`}</p>
    <h2 {...{
      "id": "estilizando-com-a-hook-usestyles",
      "style": {
        "position": "relative"
      }
    }}>{`Estilizando com a hook `}<code parentName="h2" {...{
        "className": "language-text"
      }}>{`useStyles`}</code></h2>
    <p>{`A função `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`useStyles`}</code>{` é um `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/hooks-intro.html"
      }}>{`React hook`}</a>{` que pode ser usado para gerar estilos CSS e injetá-los aos seus componentes customizados. A hook retorna um objeto contendo:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`classes`}</code>{`: Um mapa contendo os nomes de classes gerados, para serem usados como a prop `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`className`}</code>{` de seus componentes`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`css`}</code>{`: A função `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`css`}</code>{` do emotion usada para gerar as classes`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`theme`}</code>{`: O tema atual do Bold`}</li>
    </ul>
    <Demo src='pages/usage/styles/UseStyles' mdxType="Demo" />
    <h3 {...{
      "id": "usando-a-funcao-css",
      "style": {
        "position": "relative"
      }
    }}>{`Usando a função `}<code parentName="h3" {...{
        "className": "language-text"
      }}>{`css`}</code></h3>
    <p>{`Você também pode usar a função `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`css`}</code>{` para criar estilos CSS:`}</p>
    <Demo src='pages/usage/styles/Css' mdxType="Demo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      